.navBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 150px;
  padding: 0 24px;
  background-color: var(--color-white);
  color: inherit;
}

.imageIcon {
  height: 112px;
}

.navLinks {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.frameIcons {
  display: flex;
  justify-content: end;
  align-items: center;
  gap: 20px;
}

@media (max-width: 600px) {
  .navBar {
    flex-direction: column;
    height: auto;
  }

  .navLinks,
  .frameIcons {
    flex-direction: column;
    width: 100%;
  }
}

.navLinks a {
  text-decoration: none;
}

.navLinks .home,
.navLinks .about,
.navLinks .academics,
.navLinks .activities,
.navLinks .login,
.navLinks .contact {
  color: grey;
}

.navLinks .prospectus {
  color: black;
  font-weight: bold;
  text-decoration: underline;
}

.navLinks .home.active,
.about.active,
.academics.active,
.activities.active,
.login.active,
.contact.active {
  color: #2f3192;
}
