@import url("https://fonts.googleapis.com/css2?family=Roboto:wght@400&display=swap");
body {
  margin: 0;
  line-height: normal;
}
:root {
  /* fonts */
  --font-roboto: Roboto;
  --font-inherit: inherit;

  /* font sizes */
  --font-size-2xs: 11px;
  --font-size-33xl: 52px;
  --font-size-smi: 13px;
  --font-size-base: 16px;
  --font-size-mini: 15px;
  --font-size-12xl: 31px;
  --font-size-11xl: 30px;
  --font-size-29xl: 48px;
  --font-size-45xl: 64px;
  --font-size-sm: 14px;
  --font-size-xl: 20px;
  --font-size-17xl: 36px;
  --font-size-49xl: 68px;
  --font-size-50xl: 69px;
  --font-size-lg: 18px;
  --font-size-3xl: 22px;
  --font-size-2xl: 21px;
  --font-size-19xl: 38px;
  --font-size-9xl: 28px;
  --font-size-10xl: 29px;

  /* Colors */
  --color-white: #fff;
  --color-midnightblue: #2e3192;
  --color-whitesmoke: #f6f6f6;
  --color-gray-100: #76767f;
  --color-gray-200: #26262c;
  --color-mediumslateblue: #4747d7;
  --color-gainsboro: #d9d9d9;
  --color-darkblue: #3939c8;

  /* Paddings */
  --padding-8xl: 27px;
  --padding-2xl: 21px;

  /* border radiuses */
  --br-5xs: 8px;
}
