.component {
  display: relative;
  width: 100%;
  background-color: #fff;
}

.card-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  align-content: flex-start;
}

.component h1 {
  padding-left: 5%;
  padding-top: 5%;
  font-size: xxx-large;
  color: #2f3192;
}

.card {
  width: 30%;
  padding:5%;
  height:100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.card img {
  width: 350px;
  height: 300px;
  display:block;
}

.card-title {
  position: absolute;
  color: white;
  text-shadow: 2px 2px 4px rgba(0,0,0,0.5);
  font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
  font-size: xx-large;
  transition: transform 0.3s ease-in-out;
  text-align: center;
  z-index: 2;
}

.card-caption {
  visibility: hidden;
  opacity: 0;
  transition: visibility 0s, opacity 0.5s linear;
  text-align: center;
  font-size: large;
}

.card:hover .card-caption {
  visibility: visible;
  opacity: 1;
}

.card:hover .card-title {
  transform: scale(1.1);
}

.image-container {
  width: 350px;
  height: 300px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
}

.image-container::before {
  content: "";
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: inherit;
}

.image-container img {
  position: relative;
  mix-blend-mode: multiply;
  width: 100%;
  height: 100%;
  transition: filter 0.3s ease-in-out;
  object-fit: cover;
}

.card:hover .image-container img {
  filter: blur(2px);
}

@media (max-width: 768px) {
  .card {
    width: 100%;
  }
}
