.home,
.image {
  cursor: pointer;
}
.image {
  border: 0;
  padding: 0;
  background-color: transparent;
  position: absolute;
  top: 19px;
  left: 24px;
  width: 96px;
  height: 112px;
  background-image: url(/public/image@2x.png);
  background-size: cover;
  background-repeat: no-repeat;
  background-position: top;
}
.home {
  color: inherit;
  width: 45px;
}
.about,
.academics,
.activities,
.contact,
.download,
.home {
  text-decoration: none;
  position: relative;
  line-height: 30px;
  display: inline-block;
  height: 36px;
  flex-shrink: 0;
}
.about {
  font-size: var(--font-size-mini);
  color: var(--color-darkblue);
  width: 48px;
}
.academics,
.activities,
.contact,
.download {
  color: inherit;
}
.academics {
  font-size: var(--font-size-smi);
  width: 83px;
  cursor: pointer;
}
.activities,
.contact,
.download {
  width: 72px;
}
.contact,
.download {
  width: 61px;
}
.download {
  width: 78px;
}
.frame,
.vectorIcon {
  position: absolute;
  overflow: hidden;
}
.frame {
  top: 58px;
  left: calc(50% - 284px);
  width: 568px;
  height: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 36px;
}
.vectorIcon {
  height: 81.22%;
  width: 100%;
  top: 9.39%;
  right: 0;
  bottom: 9.39%;
  left: 0;
  max-width: 100%;
  max-height: 100%;
}
.frame2 {
  text-decoration: none;
  position: relative;
  width: 21px;
  flex-shrink: 0;
}
.frame1,
.frame2,
.frameIcon {
  height: 22px;
  overflow: hidden;
}
.frameIcon {
  position: relative;
  width: 22px;
  flex-shrink: 0;
}
.frame1 {
  position: absolute;
  top: 66px;
  right: 24px;
  width: 122px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  gap: 28px;
}
.navBar {
  position: undefined;
  background-color: var(--color-white);
  width: 1440px;
  height: 150px;
  overflow: hidden;
  flex-shrink: 0;
  margin-left: 1px;
  font-size: var(--font-size-sm);
  color: var(--color-gray-100);
}
.rectangle {
  position: absolute;
  top: -1px;
  left: 0;
  background-color: var(--color-midnightblue);
  width: 1440px;
  height: 393px;
}
.aboutUs {
  position: absolute;
  top: 100px;
  left: 387px;
  line-height: 67.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 665px;
  height: 187px;
}
.headingSection {
  position: relative;
  background-color: var(--color-midnightblue);
  width: 1442px;
  height: 387px;
  overflow: hidden;
  flex-shrink: 0;
  text-align: center;
  font-size: var(--font-size-33xl);
}
.govtDegreeCollege {
  position: absolute;
  top: calc(50% - 102.5px);
  left: calc(50% - 242px);
  font-size: var(--font-size-lg);
  line-height: 30.6px;
  color: var(--color-gray-200);
  display: flex;
  align-items: center;
  justify-content: center;
  width: 483px;
}
.k {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 455px);
  font-size: var(--font-size-49xl);
  line-height: 82.8px;
}
.currentEnrollments {
  position: absolute;
  top: calc(50% + 64.5px);
  left: calc(50% - 466px);
  line-height: 19.2px;
  color: var(--color-gray-100);
}
.div {
  position: absolute;
  top: calc(50% - 18.5px);
  left: calc(50% - 200px);
  font-size: var(--font-size-50xl);
  line-height: 82.8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 129px;
  height: 83px;
}
.qualifiedStaff {
  position: absolute;
  top: calc(50% + 64.5px);
  left: calc(50% - 186px);
  line-height: 19.2px;
  color: var(--color-gray-100);
}
.div1 {
  top: calc(50% - 18.5px);
  left: calc(50% + 107px);
  font-size: var(--font-size-50xl);
  line-height: 82.8px;
}
.div1,
.k1,
.socities {
  position: absolute;
}
.socities {
  top: calc(50% + 59.5px);
  left: calc(50% + 117px);
  line-height: 19.2px;
  color: var(--color-gray-100);
}
.k1 {
  top: calc(50% - 18.5px);
  left: calc(50% + 365px);
  font-size: var(--font-size-49xl);
  line-height: 82.8px;
}
.activeStudents {
  position: absolute;
  top: calc(50% + 64.5px);
  left: calc(50% + 369px);
  line-height: 19.2px;
  color: var(--color-gray-100);
}
.atAGlanceSection {
  position: relative;
  top: 0;
  left: 0;
  width: 1440px;
  height: 371px;
  overflow: hidden;
  margin-right: 2px;
  text-align: center;
  color: var(--color-mediumslateblue);
}
.rectangle1,
.rectangle2,
.rectangle3 {
  position: absolute;
  top: 168.03px;
  left: 720px;
  background-color: var(--color-mediumslateblue);
  width: 64px;
  height: 3px;
}
.rectangle2,
.rectangle3 {
  top: 681px;
  left: 711px;
}
.rectangle3 {
  top: 247px;
  left: 525px;
  width: 5px;
  height: 350px;
}
.rectangle4 {
  position: absolute;
  top: 171px;
  left: 720px;
  width: 64px;
  height: 3px;
}
.ourStory1 {
  position: absolute;
  top: 289px;
  left: 154px;
  line-height: 48px;
  display: inline-block;
  width: 352px;
}
.insertBackgroundHere {
  margin: 0;
}
.yearFounded {
  margin-bottom: 0;
}
.yearFoundedTotalDegreesHa {
  margin: 0;
  padding-left: var(--padding-2xl);
}
.insertBackgroundHereContainer {
  position: absolute;
  top: 255px;
  left: 603px;
  font-size: var(--font-inherit);
  line-height: 29.71px;
  font-family: var(--font-inherit);
  color: var(--color-gray-100);
  display: inline-block;
  width: 659px;
  height: 342px;
}
.ourStory {
  position: relative;
  top: 0;
  left: 0;
  width: 1438px;
  height: 757px;
  overflow: hidden;
  margin-right: 4px;
  font-size: var(--font-size-45xl);
  color: var(--color-midnightblue);
}
.rectangle5 {
  position: absolute;
  top: 171px;
  left: 704px;
  background-color: var(--color-white);
  width: 64px;
  height: 3px;
}
.insertMissionStatement {
  position: absolute;
  top: 152px;
  left: 394px;
  line-height: 29.71px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 659px;
  height: 342px;
}
.understandingTheGame {
  position: absolute;
  top: 1343px;
  left: 549px;
  font-size: var(--font-size-2xs);
  text-decoration: underline;
  line-height: 29.71px;
  color: inherit;
  display: inline-block;
  width: 327px;
}
.missionStatement1 {
  position: absolute;
  top: 86px;
  left: 471px;
  font-size: var(--font-size-45xl);
  line-height: 48px;
  color: var(--color-whitesmoke);
  display: inline-block;
  width: 577px;
  height: 50px;
}
.rectangle6 {
  position: absolute;
  top: 619px;
  left: 696px;
  background-color: var(--color-white);
  width: 64px;
  height: 3px;
}
.missionStatement {
  position: relative;
  background-color: var(--color-midnightblue);
  width: 1442px;
  height: 686px;
  overflow: hidden;
  flex-shrink: 0;
}
.imageIcon {
  position: absolute;
  top: 342px;
  left: 160px;
  width: 70px;
  height: 81px;
  object-fit: cover;
}
.frameIcon2,
.frameIcon3,
.frameIcon4 {
  position: absolute;
  top: 375px;
  left: 1178px;
  width: 18px;
  height: 18px;
  overflow: hidden;
}
.frameIcon3,
.frameIcon4 {
  left: 1220px;
}
.frameIcon4 {
  left: 1262px;
}
.addressLine1,
.addressLine2,
.govtDegreeCollege1 {
  position: absolute;
  left: 160px;
  display: inline-block;
}
.govtDegreeCollege1 {
  top: 84px;
  font-size: var(--font-size-11xl);
  line-height: 48px;
  width: 630px;
}
.addressLine1,
.addressLine2 {
  top: 164px;
  line-height: 29.71px;
  color: var(--color-gray-100);
  width: 212px;
}
.addressLine2 {
  top: 209px;
  font-size: var(--font-size-base);
  width: 223px;
}
.am200,
.mainOffice,
.number {
  position: absolute;
  left: 923px;
  display: inline-block;
}
.mainOffice {
  top: 84px;
  font-size: var(--font-size-12xl);
  line-height: 48px;
  width: 157px;
}
.am200,
.number {
  top: 164px;
  line-height: 29.71px;
  color: var(--color-gray-100);
  width: 138px;
}
.am200 {
  top: 193px;
  font-size: var(--font-size-base);
  width: 151px;
}
.about1,
.academics1,
.activities1,
.contact1,
.copyright2023,
.download1,
.home1 {
  position: absolute;
  top: 354px;
  left: 516px;
  line-height: 29.71px;
  display: inline-block;
  width: 42px;
}
.about1,
.academics1,
.activities1,
.contact1,
.copyright2023,
.download1 {
  left: 590px;
}
.academics1,
.activities1,
.contact1,
.copyright2023,
.download1 {
  left: 664px;
  width: 78px;
}
.activities1,
.contact1,
.copyright2023,
.download1 {
  left: 774px;
  width: 64px;
}
.contact1,
.copyright2023,
.download1 {
  left: 870px;
  width: 55px;
}
.copyright2023,
.download1 {
  top: 387px;
  left: 684px;
  font-size: var(--font-size-base);
  width: 72px;
}
.copyright2023 {
  top: 472px;
  left: 617px;
  font-size: var(--font-size-smi);
  line-height: 26px;
  color: var(--color-gray-100);
  text-align: center;
  width: 207px;
}
.aboutUsPage,
.infoSection {
  position: relative;
  overflow: hidden;
}
.infoSection {
  width: 1440px;
  height: 582px;
  flex-shrink: 0;
  font-size: var(--font-size-mini);
  color: var(--color-gray-200);
}
.aboutUsPage {
  background-color: var(--color-white);
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: left;
  font-size: var(--font-size-base);
  color: var(--color-white);
  font-family: var(--font-roboto);
}
