.rectangle {
  top: 168.03px;
  left: calc(50% - 32px);
  width: 64px;
  height: 3px;
}
.rectangle,
.rectangle1,
.rectangle2 {
  position: absolute;
  background-color: var(--color-mediumslateblue);
}
.rectangle1 {
  top: 681px;
  left: calc(50% - 32px);
  width: 64px;
  height: 3px;
}
.rectangle2 {
  top: 247px;
  left: 525px;
  width: 5px;
  height: 350px;
}
.rectangle3 {
  position: absolute;
  top: 171px;
  left: 720px;
  width: 64px;
  height: 3px;
}
.contactUs2 {
  position: absolute;
  top: 374px;
  left: 154px;
  line-height: 48px;
  display: inline-block;
  width: 352px;
}
.hoursPhoneNumber,
.p {
  margin: 0;
}
.p {
  font-size: var(--font-size-base);
}
.hoursPhoneNumberEmailContainer1 {
  line-break: anywhere;
  width: 100%;
}
.hoursPhoneNumberEmailContainer {
  position: absolute;
  top: 255px;
  left: 603px;
  line-height: 29.71px;
  color: var(--color-gray-100);
  display: flex;
  align-items: center;
  width: 659px;
  height: 342px;
  font-size: var(--font-size-29xl);
}
.contactUs1 {
  position: absolute;
  top: 535px;
  left: 4px;
  width: 1438px;
  height: 735px;
  overflow: hidden;
  color: var(--color-midnightblue);
}
.rectangle4 {
  position: absolute;
  top: -1px;
  left: 0;
  background-color: var(--color-midnightblue);
  width: 1440px;
  height: 393px;
}
.contactUs3 {
  position: absolute;
  top: 100px;
  left: 387px;
  line-height: 67.2px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 665px;
  height: 187px;
}
.headingSection {
  position: absolute;
  top: 150px;
  left: 0;
  background-color: var(--color-midnightblue);
  width: 1442px;
  height: 387px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-33xl);
}
.understandingTheGame {
  position: absolute;
  top: 1343px;
  left: 549px;
  text-decoration: underline;
  line-height: 29.71px;
  color: inherit;
  display: inline-block;
  width: 327px;
}
.missionStatementChild {
  position: absolute;
  top: 87px;
  left: 162px;
  background-color: var(--color-whitesmoke);
  width: 1120px;
  height: 281px;
}
.missionStatement {
  position: absolute;
  top: 1270px;
  left: -1px;
  background-color: var(--color-midnightblue);
  width: 1443px;
  height: 455px;
  overflow: hidden;
  font-size: var(--font-size-2xs);
}
.contactUs {
  position: relative;
  background-color: var(--color-white);
  width: 100%;
  height: 2332px;
  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-45xl);
  color: var(--color-white);
  font-family: var(--font-roboto);
}
