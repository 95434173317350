.headingSection {
  position: relative;
  background-color: var(--color-midnightblue);
  width: 100%;
  height: 500px;
  overflow: hidden;
  text-align: center;
  font-size: var(--font-size-33xl);
  color: var(--color-white);
  background-size: cover; /* Scale the background image to cover the container */
  background-position: center; /* Center the background image */
}

.textContainer {
  position: relative;
  top: 40%;
  line-height: 67.2px;
  display: flex;
  align-items: center;
  justify-content: center;
}
