.academicsPage {
  position: relative;
  background-color: var(--color-white);

  overflow: hidden;
  text-align: left;
  font-size: var(--font-size-sm);
  color: var(--color-gray-100);
  font-family: var(--font-roboto);
}

.subjects1 {
  position: flex;
  top: 89px;
  left: 173px;
  font-size: var(--font-size-29xl);
  line-height: 48px;
  font-family: var(--font-roboto);
  color: var(--color-midnightblue);
  display: inline-block;
  width: 352px;
}
